.logo {
  height: 140px;
  width: 140px;
  margin:10;
  border-radius:50%  !important;
  horizontal-aligmnet:center;
  aligmnet:center;
}

input[type="file"] {
  display: none;
}

ion-content {
  --padding-bottom: 0px;
  --padding-end: 0px;
  --padding-start: 0px;
  --padding-top: 0px;
  --background: #000 url("../images/background.jpg") no-repeat center center/cover;
  --ion-item-background: transparent;
}

img {
  height: 140px;
  width: 140px;
  margin:20;
  border-radius:50%  !important;
  background: transparent;

}

.image-crop{
  width: 100px !important;
  height: 100px !important;
  object-fit: cover !important; 
  border-radius:20% !important;
  background: transparent !important;
  horizontal-aligmnet:center !important; 
}

.image-meter{
  width: 200px !important;
  height: 200px !important;
  object-fit: cover !important; 
  border-radius:10% !important;
  background: transparent !important;
  horizontal-aligmnet:center !important; 
}

.product-uri {
  height: 100%;
  width: 100%;
}

ion-img {
  --border-radius: 50% !important;
  --ion-item-background: transparent;
}

ion-list {
  --inner-padding-bottom: 0;
  --inner-padding-end: 0;
  --inner-padding-start: 0;
  --inner-padding-top: 0;
  --ion-item-background: transparent;
}

ion-grid-row {
  --inner-margin-bottom: 0;
  --inner-margin-top: 0;
}

list-md {
  --inner-padding-top: 0;
  --inner-padding-bottom: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  --margin-top: 0;
  --margin-bottom: 0;
  --gutter: 0;
}

.searchbar-input.sc-ion-searchbar-md {
  border-radius: 25px;
  border: solid 2px;
  background-position: left 5px center;
  height: auto;
  width: 80%;
  font-size: 1.1rem;
  font-weight: 400;
  color: gray;
  background-color: black;
  -webkit-box-shadow: 0 8px 14px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 8px 14px rgba(0, 0, 0, 0.1) !important;
}